import logo from './logo.svg';
import './App.css';
import { Inewstheme } from './theme'
import Comingsoon from './components/Comingsoon';
import { ThemeProvider } from '@material-ui/core'

function App() {
  return (
    <ThemeProvider theme={Inewstheme}>
      <Comingsoon></Comingsoon>
    </ThemeProvider>
    
  );
}

export default App;
