import { makeStyles } from '@material-ui/core';
import React from 'react'
import './Comingsoon.css'

const useStyles = makeStyles((theme) => ({
    scaffold: {
        padding: 0,
        margin: 0,
        boxSizing: 'border-box',
    }
}));

export default function Comingsoon() {
    const classes = useStyles();
    return (
        <div className={classes.scaffold}>
            <div class="overlay"></div>
            <div class="stars" aria-hidden="true"></div>
            <div class="starts2" aria-hidden="true"></div>
            <div class="stars3" aria-hidden="true"></div>
            <main class="main">
                <section class="contact">
                    <h1 className="title">Arunai Associates</h1>
                    <h2 class="sub-title">Site Under Construction</h2>
                </section>
            </main> 
        </div>
    );
}
